import axios from "axios";
import { Message } from "element-ui";
// import router from "./../router";

const http = axios.create({
  timeout: 20000,
  baseURL: "https://api-lk.gamemorefun.com"
});

// 请求拦截
http.interceptors.request.use(
  config => {
    if (localStorage.getItem("token")) {
      config.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    }
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 响应拦截
http.interceptors.response.use(
  response => {
    // console.log('服務器自身自带的状态码为response.status',response,response.status);
    if (response.status !== 200) {
      Message({
        type: "warning",
        message: "服務器請求超時,請稍後重試！"
      });
    }

    if (response.data.status == 4003) {
      Message({
        type: "warning",
        message: "登錄已過期，請重新登錄！"
      });
      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
      }, 800);
    }

    return response.data;
  },
  error => {
    Message({
      type: "error",
      message: "服務器錯誤，請檢查！"
    });
    setTimeout(() => {
      return Promise.reject(error);
    }, 1000);
  }
);

export default http;
