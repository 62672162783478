import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueParticles from "vue-particles";
import utils from "../../utils/urlGet";
// import getUrlKey from "../../utils/urlGet"
import http from "../../utils/config";
import VueBus from "vue-bus";
import {
	Toast
} from "vant";

import {
	Form,
	FormItem,
	Input,
	Button,
	Select,
	Radio,
	RadioGroup,
	Option,
	MessageBox
} from "element-ui";

import "vant/lib/index.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/swiper-bundle.css";
import "./assets/styles/common.css";
import "./assets/styles/animate.css";

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(VueBus);
Vue.use(Toast);
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Option);
// Vue.use(MessageBox);
Vue.use(VueAwesomeSwiper);
Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.prototype.Request = Request;
Vue.prototype.$utils = utils;
// Vue.prototype.$getUrlKey = getUrlKey;
Vue.prototype.$http = axios;
Vue.prototype.$axios = http;


Vue.prototype.getQueryString = function (name) {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
	var r = window.location.search.substr(1).match(reg); // 匹配目标参数
	console.log('name', name, 'reg', reg, 'r', r)
	if (r != null) return unescape(r[2]);
	return null; // 返回参数值
}

/*
 * 封装ajax
 * obj : 全局this
 * data : ajax传入后台data数据
 * address : ajax接口地址
 * fn : 成功返回方法  带参数  obj,data  obj:this, data:response
 * */

// 测试服
// var baseUrl = "https://devapi.morefun.zone";
// 正式服
var baseUrl = "https://api-lk.gamemorefun.com";
var querystring = require("querystring");

Vue.prototype.postHttp = function (obj, data, address, fn) {
	http.post(baseUrl + address, querystring.stringify(data)).then(
		response => {
			fn(obj, response);
		},
		response => {
			console.log(response);
		}
	);
};

Vue.prototype.getHttp = function (obj, data, address, fn) {
	http
		.get(baseUrl + address, {
			params: data
		})
		.then(function (response) {
			fn(obj, response);
		})
		.catch(function (response) {
			console.log(response);
		});
};

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#pc");